import { makeAutoObservable } from "mobx";
import { TypeGarment } from "../interfaces/typeGarment";
import axios from "axios";
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class TypeGarmentStore {
  types: TypeGarment[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchTypes() {
    try {
      const response = await axios.get(`${apiUrl}/types`);
      this.types = response.data;
    } catch (error: any) {
      console.error("Error fetching types:", error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async addType(type: TypeGarment) {
    try {
      const response = await axios.post(`${apiUrl}/types`, type);
      this.types.push(response.data);
    } catch (error: any) {
      console.error("Error adding type:", error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while inserting')
    }
  }

  async updateType(id: string, updatedType: TypeGarment) {
    try {
      await axios.put(`${apiUrl}/types/${id}`, updatedType);
      const index = this.types.findIndex((type) => type._id === id);
      if (index !== -1) {
        this.types[index] = updatedType;
      }
    } catch (error: any) {
      console.error("Error updating type:", error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  async deleteType(id: string) {
    try {
      await axios.delete(`${apiUrl}/types/${id}`);
      this.types = this.types.filter((type) => type._id !== id);
    } catch (error: any) {
      console.error("Error deleting type:", error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const typeGarmentStore = new TypeGarmentStore();
export default typeGarmentStore;
