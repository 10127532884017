import axios from 'axios';
import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import appStore from './AppStore';
import { setAxiosHeader } from '../services/AuthService';

const apiUrl =  process.env.REACT_APP_API_URL;

class AuthStore {
  @observable userInfo = null;
  @observable isGettingMe = false;
  @observable isAuthenticating = false;
  @observable authError = false;

  constructor() {
    makeObservable(this);
  }

  @computed get isAuthenticated() {
    return !!this.userInfo;
  }

  @action
  async getMe() {
    if(this.isGettingMe) return;
    this.isGettingMe = true;
    try {
      const response = await axios.get(`${apiUrl}/users/me`);
      runInAction(() => {
        this.userInfo = response.data;
        this.isGettingMe = false;   
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.isGettingMe = false;
    }
  }

  @action
  async checkPassword(password: string) {
    try {
      const response = await axios.post(`${apiUrl}/check-password`, {password});
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return 'ko';
    }
  }

  @action
  async authenticate(login: string, password: string) {
    this.isAuthenticating = true;
    try {
      const response = await axios.post(`${apiUrl}/login`, {login, password});
      this.userInfo = response.data.info;
      setAxiosHeader(response.data.token);
      this.isAuthenticating = false;
    } catch (error: any) {
      console.error('Error fetching data:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while login')
      this.isAuthenticating = false;
    }
  }

  @action
  logOut() {
    localStorage.removeItem('token');
    this.userInfo = null;
  }
}

const store = new AuthStore();
export default store;