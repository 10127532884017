import axios from "axios";

export const setAxiosHeader = (token: string) => {
  localStorage.setItem('token', token);
  axios.interceptors.request.clear();
  axios.interceptors.request.use((config) => {
    if (config.headers.Authorization) {
      delete config.headers.Authorization;
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const hasToken = () => {
  return localStorage.getItem('token');
}