import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { Color } from '../interfaces/color';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class ColorStore {
  colors: Color[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchColors() {
    try {
      const response = await axios.get(`${apiUrl}/colors`);
      this.colors = response.data;
    } catch (error: any) {
      console.error('Error fetching colors:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async fetchColorById(id: string) {
    try {
      const response = await axios.get(`${apiUrl}/colors/${id}`);
      return response.data;
    } catch (error: any) {
      console.error(`Error fetching color with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async addColor(color: Color) {
    try {
      const response = await axios.post(`${apiUrl}/colors`, color);
      this.colors.push(response.data);
    } catch (error: any) {
      console.error('Error adding color:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  async updateColor(id: string, color: Color) {
    try {
      await axios.put(`${apiUrl}/colors/${id}`, color);
      const index = this.colors.findIndex(c => c._id === id);
      if (index !== -1) {
        this.colors[index] = { ...color, _id: id };
      }
    } catch (error: any) {
      console.error(`Error updating color with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  async deleteColor(id: string) {
    try {
      await axios.delete(`${apiUrl}/colors/${id}`);
      this.colors = this.colors.filter(c => c._id !== id);
    } catch (error: any) {
      console.error(`Error deleting color with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const colorStore = new ColorStore();
export default colorStore;
