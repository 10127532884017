import Routes from './routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import stores from './stores';
import './App.css';

function App() {
  return (
    <Provider {...stores}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
