import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { Garment } from '../interfaces/garment';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class GarmentStore {
  garments: Garment[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchGarments(): Promise<void> {
    try {
      const response = await axios.get<Garment[]>(`${apiUrl}/garments`);
      this.garments = response.data;
    } catch (error: any) {
      console.error('Error fetching garments:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async addGarment(garment: Garment): Promise<void> {
    try {
      const response = await axios.post<Garment>(`${apiUrl}/garments`, garment);
      this.garments.push(response.data);
    } catch (error: any) {
      console.error('Error adding garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  async updateGarment(id: string, updatedGarment: Garment): Promise<void> {
    try {
      const response = await axios.put<Garment>(`${apiUrl}/garments/${id}`, updatedGarment);
      const index = this.garments.findIndex((garment) => garment._id === id);
      if (index !== -1) {
        this.garments[index] = response.data;
      }
    } catch (error: any) {
      console.error('Error updating garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  async deleteGarment(id: string): Promise<void> {
    try {
      await axios.delete(`${apiUrl}/garments/${id}`);
      this.garments = this.garments.filter((garment) => garment._id !== id);
    } catch (error: any) {
      console.error('Error deleting garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const garmentStore = new GarmentStore();
export default garmentStore;
