import { action, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { User } from '../interfaces/user';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

export class UserStore {
  users: User[] = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      fetchUsers: action,
      addUser: action,
      updateUser: action,
      deleteUser: action,
    });
  }

  async fetchUsers(): Promise<void> {
    try {
      const response = await axios.get(`${apiUrl}/users`);
      this.users = response.data;
    } catch (error: any) {
      console.error('Failed to fetch users:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async addUser(user: User): Promise<void> {
    try {
      await axios.post(`${apiUrl}/users`, user);
      this.fetchUsers();
    } catch (error: any) {
      console.error('Failed to add user:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  async updateUser(id: string, user: User, showSuccess?: boolean): Promise<void> {
    try {
      await axios.put(`${apiUrl}/users/${id}`, user);
      if (showSuccess) {
        appStore.showToast(true, 'success', 'your info has been updated')
      } else {
        this.fetchUsers();
      }
    } catch (error: any) {
      console.error('Failed to update user:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  async deleteUser(id: string): Promise<void> {
    try {
      await axios.delete(`${apiUrl}/users/${id}`);
      this.fetchUsers();
    } catch (error: any) {
      console.error('Failed to delete user:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const userStore = new UserStore();
export default userStore;
