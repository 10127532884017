import { observable, action, makeObservable } from 'mobx';
import axios from 'axios';
import { Size } from '../interfaces/size';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class SizeStore {
  @observable loading = false;
  @observable updating = false;
  @observable sizes: Size[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async fetchSizes() {
    this.loading = true;
    try {
      const response = await axios.get(`${apiUrl}/sizes`);
      this.sizes = response.data;
    } catch (error: any) {
      console.error('Error fetching sizes:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
    this.loading = false;
  }

  @action
  async addSize(size: Size) {
    this.updating = true;
    try {
      const response = await axios.post(`${apiUrl}/sizes`, size);
      this.sizes.push(response.data);
    } catch (error: any) {
      console.error('Error adding size:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
    this.updating = false;
  }

  @action
  async updateSize(id: string, updatedSize: Size) {
    this.updating = true;
    try {
      const response = await axios.put(`${apiUrl}/sizes/${id}`, updatedSize);
      const index = this.sizes.findIndex(size => size._id === id);
      if (index !== -1) {
        this.sizes[index] = response.data;
      }
    } catch (error: any) {
      console.error('Error updating size:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
    this.updating = false;
  }

  @action
  async deleteSize(id: string) {
    this.updating = true;
    try {
      await axios.delete(`${apiUrl}/sizes/${id}`);
      this.sizes = this.sizes.filter(size => size._id !== id);
    } catch (error: any) {
      console.error('Error deleting size:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
    this.updating = false;
  }
}

const sizeStore = new SizeStore();
export default sizeStore;
