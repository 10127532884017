import React, { FC, useEffect, lazy, Suspense, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Toast from '../components/common/Toast';
import { hasToken, setAxiosHeader } from '../services/AuthService';
import IndeterminateCircularProgress from '../components/common/CircularProgress';
import OverviewDialog from '../components/common/OverviewDialog';
import NotFoundPage from '../components/common/404';
import ProtectedRoute from './ProtectedRoute';
import axios from 'axios';

const LazyLogin = lazy(() => import('../components/login'));
const LazyHome = lazy(() => import('../components/home'));
const LazyClientTable = lazy(() => import('../components/client/ClientTable'));
const LazySizeTable = lazy(() => import('../components/size/SizeTable'));
const LazyColorTable = lazy(() => import('../components/color/ColorTable'));
const LazyTypeGarmentTable = lazy(() => import('../components/type/TypeGarmentTable'));
const LazyDesignTable = lazy(() => import('../components/design/DesignTable'));
const LazyGarmentTable = lazy(() => import('../components/garment/GarmentTable'));
const LazyAddressTable = lazy(() => import('../components/address/AddressTable'));
const LazySupplierTable = lazy(() => import('../components/supplier/SupplierTable'));
const LazyFundTable = lazy(() => import('../components/fund/FundTable'));
const LazyCLientOrderTable = lazy(() => import('../components/client_order/ClientOrderTable'));
const LazyBareGarmentTable = lazy(() => import('../components/bare_garment/BareGarmentTable'));
const LazyGarmentOrderTable = lazy(() => import('../components/garment_order/GarmentOrderTable'));
const LazyProfile = lazy(() => import('../components/user/Profile'));
const LazyFeedBackTable = lazy(() => import('../components/feed_back/FeedBackTable'));
const LazyCriteriaTable = lazy(() => import('../components/criteria/CriteriaTable'));

const AppRoute: FC<any> = inject('appStore', 'authStore')(
  observer(({ appStore, authStore }) => {
    const [init, setInit] = useState(true);
    useEffect(() => {
      const token = hasToken();
      if (token) {
        setAxiosHeader(token);
        authStore.getMe();
      } else {
        authStore.isGettingMe = false;
      }
      setInit(false);
      axios.interceptors.response.use(
        (response) => {
          // Return the response if it's successful
          if (response.headers['token']) {
            // Update the token in the Axios instance
            setAxiosHeader(response.headers.token);
            // axios.defaults.headers.common['Authorization'] = `Bearer ${response.headers.token}`;
          }
          return response;
        },
        async (error) => {
          // Check if the error status is 401 Unauthorized
          if (error.response && error.response.status === 401) {
            appStore.showToast(true, 'error', 'Your session has been expired')
            setTimeout(() => {
              window.location.reload();
            }, 1000);
        }
        // If it's not a 401 error, forward the error
        return Promise.reject(error);
      });
    }, []);

    if (authStore.isGettingMe || init) {
      return (
        <div className="w-full h-screen flex items-center justify-center">
          <IndeterminateCircularProgress />
        </div>
      );
    }
    const handleClose = () => {
      appStore.closeOverview()
    }
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LazyLogin />} />
            <Route path="/" element={<ProtectedRoute><LazyHome /></ProtectedRoute>} />
            <Route path="/client" element={<ProtectedRoute><LazyClientTable /></ProtectedRoute>} />
            <Route path="/size" element={<ProtectedRoute><LazySizeTable /></ProtectedRoute>} />
            <Route path="/color" element={<ProtectedRoute><LazyColorTable /></ProtectedRoute>} />
            <Route path="/type" element={<ProtectedRoute><LazyTypeGarmentTable /></ProtectedRoute>} />
            <Route path="/design" element={<ProtectedRoute><LazyDesignTable /></ProtectedRoute>} />
            <Route path="/garment" element={<ProtectedRoute><LazyGarmentTable /></ProtectedRoute>} />
            <Route path="/client/address" element={<ProtectedRoute><LazyAddressTable /></ProtectedRoute>} />
            <Route path="/supplier/address" element={<ProtectedRoute><LazyAddressTable /></ProtectedRoute>} />
            <Route path="/supplier" element={<ProtectedRoute><LazySupplierTable /></ProtectedRoute>} />
            <Route path="/fund" element={<ProtectedRoute><LazyFundTable /></ProtectedRoute>} />
            <Route path="/order" element={<ProtectedRoute><LazyCLientOrderTable /></ProtectedRoute>} />
            <Route path="/bare-garment" element={<ProtectedRoute><LazyBareGarmentTable /></ProtectedRoute>} />
            <Route path="/garment-order" element={<ProtectedRoute><LazyGarmentOrderTable /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><LazyProfile /></ProtectedRoute>} />
            <Route path="/feed-back" element={<ProtectedRoute><LazyFeedBackTable /></ProtectedRoute>} />
            <Route path="/criteria" element={<ProtectedRoute><LazyCriteriaTable /></ProtectedRoute>} />
            {/* 404 Page */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        {appStore.showToastMessage && <Toast />}
        {appStore.showOverviewDialog && <OverviewDialog imageUrl={appStore.overviewUrl} onClose={handleClose} />}
      </>
    );
  })
);

export default AppRoute;
