import { action, makeAutoObservable, observable } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import { Address } from '../interfaces/address';
import appStore from './AppStore';
import { Client } from '../interfaces/client';
import { Supplier } from '../interfaces/supplier';

const apiUrl =  process.env.REACT_APP_API_URL;

class AddressStore {
  @observable isLoading = false;
  @observable selectedClient: Client | null = null;
  @observable selectedSupplier: Supplier | null = null;
  @observable addresses: Address[] = [];
  @observable countries: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action 
  setSupplier(supplier: Supplier | null) {
    this.selectedClient = null;
    this.selectedSupplier = supplier;
  }

  @action 
  setClient(client: Client | null) {
    this.selectedSupplier = null;
    this.selectedClient = client;
  }

  @action
  async fetchCountries(): Promise<void> {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        this.countries = response.data.map(
          (country: any) => country.name.common
        );
      })
      .catch((error) => {
        console.error("Failed to fetch countries:", error);
        appStore.showToast(true, 'error', error?.message || 'An error occurred while fetching countries')
      });
  }

  @action
  async fetchAddresses(): Promise<void> {
    try {
      const response: AxiosResponse<Address[]> = await axios.get(`${apiUrl}/addresses`, {params: {supplier: this.selectedSupplier?._id, client: this.selectedClient?._id}});
      this.addresses = response.data;
    } catch (error: any) {
      console.error('Failed to fetch addresses:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  @action
  async fetchAddressById(id: string): Promise<void> {
    try {
      const response: AxiosResponse<Address> = await axios.get(`${apiUrl}/addresses/${id}`);
      const address = response.data;
      const existingAddress = this.addresses.find(a => a._id === address._id);
      if (existingAddress) {
        Object.assign(existingAddress, address);
      } else {
        this.addresses.push(address);
      }
    } catch (error: any) {
      console.error(`Failed to fetch address with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  @action
  async addAddress(address: Address): Promise<void> {
    try {
      const response: AxiosResponse<Address> = await axios.post(`${apiUrl}/addresses`, address);
      this.addresses.push(response.data);
    } catch (error: any) {
      console.error('Failed to add address:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  @action
  async updateAddress(id: string, updatedAddress: Partial<Address>): Promise<void> {
    try {
      const response: AxiosResponse<Address> = await axios.put(`${apiUrl}/addresses/${id}`, updatedAddress);
      const address = response.data;
      const index = this.addresses.findIndex(a => a._id === address._id);
      if (index !== -1) {
        Object.assign(this.addresses[index], address);
      }
    } catch (error: any) {
      console.error(`Failed to update address with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  @action
  async deleteAddress(id: string): Promise<void> {
    try {
      await axios.delete(`${apiUrl}/addresses/${id}`);
      this.addresses = this.addresses.filter(address => address._id !== id);
    } catch (error: any) {
      console.error(`Failed to delete address with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }
}

const addressStore = new AddressStore();
export default addressStore;
