import { FC, useEffect, useRef } from 'react';

interface OverviewDialogProps {
  imageUrl: string;
  onClose: () => void;
}

const OverviewDialog: FC<OverviewDialogProps> = ({ imageUrl, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  // Attach event listeners when the component mounts
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div ref={modalRef} className="max-w-3xl mx-auto p-4">
        <img src={imageUrl} alt="Pic" className="w-full h-auto" />
      </div>
      <button
        className="absolute top-0 right-0 m-4 text-white focus:outline-none"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};

export default OverviewDialog;
