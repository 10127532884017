const IndeterminateCircularProgress = () => {
  return (
    <svg className="w-16 h-16 animate-spin" viewBox="0 0 32 32">
      <circle
        className="text-blue-500 stroke-current"
        strokeWidth="2"
        fill="transparent"
        r="15.91549430918954"
        cx="16"
        cy="16"
      />
    </svg>
  );
};

export default IndeterminateCircularProgress;