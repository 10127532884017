import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Layout from '../components/common/Layout';

const ProtectedRoute: FC<any> = inject('authStore')(
  observer(({ authStore, children }) => {
    const { isAuthenticated } = authStore;
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />
    }
    return (<Layout>
      {children}
    </Layout>);
  })
);

export default ProtectedRoute;