import { action, makeAutoObservable, observable } from 'mobx';
import axios from 'axios';
import { FeedBack } from '../interfaces/feedBack';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class FeedBackStore {
  @observable feedbacks: FeedBack[] = [];
  @observable loading: boolean = false;
  @observable error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchFeedBacks() {
    try {
      this.loading = true;
      const response = await axios.get(`${apiUrl}/feedbacks`);
      this.feedbacks = response.data;
      this.loading = false;
    } catch (error: any) {
      this.error = error.message;
      this.loading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  @action
  async fetchOneFeedBack(id: string) {
    try {
      this.loading = true;
      const response = await axios.get(`${apiUrl}/feedbacks/${id}`);
      return response.data;
    } catch (error: any) {
      this.error = error.message;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    } finally {
      this.loading = false;
    }
  }

  @action
  async addFeedBack(feedback: FeedBack) {
    try {
      this.loading = true;
      const response = await axios.post(`${apiUrl}/feedbacks`, feedback);
      this.feedbacks.push(response.data);
      this.loading = false;
    } catch (error: any) {
      this.error = error.message;
      this.loading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  @action
  async updateFeedBack(id: string, feedback: FeedBack) {
    try {
      this.loading = true;
      const response = await axios.put(`${apiUrl}/feedbacks/${id}`, feedback);
      const index = this.feedbacks.findIndex((item) => item._id === id);
      if (index !== -1) {
        this.feedbacks[index] = response.data;
      }
      this.loading = false;
    } catch (error: any) {
      this.error = error.message;
      this.loading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  @action
  async deleteFeedBack(id: string) {
    try {
      this.loading = true;
      await axios.delete(`${apiUrl}/feedbacks/${id}`);
      this.feedbacks = this.feedbacks.filter((item) => item._id !== id);
      this.loading = false;
    } catch (error: any) {
      this.error = error.message;
      this.loading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const feedBackStore = new FeedBackStore();
export default feedBackStore;
