import { observable, action, makeObservable, runInAction } from 'mobx';
import { Statistic } from '../interfaces/statistic';
import axios from 'axios';

const apiUrl =  process.env.REACT_APP_API_URL;

const getUrl = (label: string) => {
  switch(label) {
    case "Undelivered orders":
    case "All orders": 
      return "/order";
    case "All garments": 
      return "/garment";
    case "All designs": 
      return "/design";
    case "Unprinted garments": 
      return "/bare-garment";
    default: 
      return "/client";
  }
}

class AppStore {
  @observable statistics: (Statistic & {url?: string})[] = [];
  @observable loading = false;
  @observable showToastMessage = false;
  @observable showOverviewDialog = false;
  @observable overviewUrl = "";
  @observable toastType = "error";
  @observable toastMessage = "An error occurred";

  constructor() {
    makeObservable(this);
  }

  @action fetchStats() {
    this.loading = true;
    axios.get(`${apiUrl}/statistics`)
      .then(response => {
        this.statistics = response.data.map((stat: Statistic) => {
          return {
            ...stat,
            url: getUrl(stat.label)
          }
        });
      })
      .catch(error => {
        console.error('Failed to fetch statistics:', error);
        this.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching statistics')
      }).finally(() => (this.loading = false));
  }

  @action 
  showToast(show = true, type: "error" | "success" = "error", message = "An error occurred", duration = 3000) {
    this.showToastMessage = show;
    this.toastType = type;
    this.toastMessage = message;
    setTimeout(() => {
      runInAction(() => (this.showToastMessage = false));
    }, duration);
  }

  @action 
  showOverview(imageUrl: string) {
    this.showOverviewDialog = true;
    this.overviewUrl = imageUrl;
  }

  @action
  closeOverview() {
    this.showOverviewDialog = false;
  }

}

const store = new AppStore();
export default store;

