import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { GarmentOrder } from '../interfaces/garmentOrder';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class GarmentOrderStore {
  garmentOrders: GarmentOrder[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchGarmentOrders = async () => {
    try {
      const response = await axios.get(`${apiUrl}/garment-orders`);
      this.garmentOrders = response.data;
    } catch (error: any) {
      console.error('Error fetching garment orders:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  };

  getGarmentOrder = async (id: string) => {
    try {
      const response = await axios.get(`${apiUrl}/garment-orders/${id}`);
      return response.data;
    } catch (error: any) {
      console.error(`Error fetching garment order with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  };

  addGarmentOrder = async (garmentOrder: GarmentOrder) => {
    try {
      await axios.post(`${apiUrl}/garment-orders`, garmentOrder);
      this.fetchGarmentOrders();
    } catch (error: any) {
      console.error('Error adding garment order:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  };

  updateGarmentOrder = async (id: string, garmentOrder: GarmentOrder) => {
    try {
      await axios.put(`${apiUrl}/garment-orders/${id}`, garmentOrder);
      this.fetchGarmentOrders();
    } catch (error: any) {
      console.error(`Error updating garment order with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  };

  deleteGarmentOrder = async (id: string) => {
    try {
      await axios.delete(`${apiUrl}/garment-orders/${id}`);
      this.garmentOrders = this.garmentOrders.filter(order => order._id !== id);
    } catch (error: any) {
      console.error(`Error deleting garment order with ID ${id}:`, error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while removing')
    }
  };
}

const garmentOrderStore = new GarmentOrderStore();
export default garmentOrderStore;
