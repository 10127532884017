import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { FundOperation } from '../interfaces/fund';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class FundStore {
  operations: FundOperation[] = [];
  currentStatus: FundOperation | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchOperations(): Promise<void> {
    try {
      const response = await axios.get(`${apiUrl}/fund/operations`);
      this.operations = response.data;
    } catch (error: any) {
      console.error('Failed to fetch fund operations:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching operations')
    }
  }

  async addOperation(operation: FundOperation): Promise<void> {
    try {
      await axios.post(`${apiUrl}/fund`, operation);
      // Optionally, you can fetch the updated operations list after adding a new operation
      await this.fetchOperations();
      this.getLastStatus();
    } catch (error: any) {
      console.error('Failed to add fund operation:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  async updateOperation(id: string, operation: FundOperation): Promise<void> {
    try {
      await axios.put(`${apiUrl}/fund/${id}`, operation);
      // Optionally, you can fetch the updated operations list after updating the operation
      await this.fetchOperations();
      this.getLastStatus();
    } catch (error: any) {
      console.error('Failed to update fund operation:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  async deleteOperation(id: string): Promise<void> {
    try {
      await axios.delete(`${apiUrl}/fund/${id}`);
      // Optionally, you can fetch the updated operations list after deleting the operation
      await this.fetchOperations();
    } catch (error: any) {
      console.error('Failed to delete fund operation:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while removing')
    }
  }

  async getLastStatus(): Promise<void> {
    try {
      const response = await axios.get(`${apiUrl}/fund/status`);
      // Handle the response for getting the last status if needed
      this.currentStatus = response.data;
    } catch (error: any) {
      console.error('Failed to get last fund status:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while getting status')
    }
  }
}

const fundStore = new FundStore();
export default fundStore;
