import { observable, action, makeAutoObservable } from 'mobx';
import { BareGarment } from '../interfaces/bareGarment';
import axios from 'axios';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class BareGarmentStore {
  @observable
  bareGarments: BareGarment[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchBareGarments() {
    try {
      const response = await axios.get(`${apiUrl}/bare-garments`);
      this.bareGarments = response.data;
    } catch (error: any) {
      console.error('Error fetching bare garments:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  @action
  async addBareGarment(bareGarment: BareGarment) {
    try {
      const response = await axios.post(`${apiUrl}/bare-garments`, bareGarment);
      const addedGarment = response.data;
      this.bareGarments.push(addedGarment);
    } catch (error: any) {
      console.error('Error adding bare garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while adding')
    }
  }

  @action
  async updateBareGarment(updatedGarment: BareGarment) {
    try {
      const saved = await axios.put(`${apiUrl}/bare-garments/${updatedGarment._id}`, updatedGarment);
      const index = this.bareGarments.findIndex((garment) => garment._id === updatedGarment._id);
      if (index !== -1) {
        this.bareGarments[index] = saved.data;
      }
    } catch (error: any) {
      console.error('Error updating bare garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  }

  @action
  async deleteBareGarment(id: string) {
    try {
      await axios.delete(`${apiUrl}/bare-garments/${id}`);
      this.bareGarments = this.bareGarments.filter((garment) => garment._id !== id);
    } catch (error: any) {
      console.error('Error deleting bare garment:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

const bareGarmentStore = new BareGarmentStore();
export default bareGarmentStore;
