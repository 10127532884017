import { observable, action, makeObservable } from 'mobx';
import axios from 'axios';
import appStore from './AppStore';
import { Client } from '../interfaces/client';

const apiUrl =  process.env.REACT_APP_API_URL;

class ClientStore {
  @observable loading = false;
  @observable updating = false;
  @observable clients: Client[] = [];

  constructor() {
    makeObservable(this);
  }

  @action fetchClients() {
    this.loading = true;
    axios.get(`${apiUrl}/clients`)
      .then(response => {
        this.clients = response.data;
      })
      .catch(error => {
        console.error('Failed to fetch clients:', error);
        appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
      }).finally(() => (this.loading = false));
  }

  @action createClient(clientData: Client) {
    this.updating = true;
    axios.post(`${apiUrl}/clients`, clientData)
      .then(response => {
        this.clients.push(response.data);
      })
      .catch(error => {
        console.error('Failed to create client:', error);
        appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while creating')
      }).finally(() => (this.updating = false));
  }

  @action updateClient(clientId: string, clientData: Client) {
    this.updating = true;
    axios.put(`${apiUrl}/clients/${clientId}`, clientData)
      .then(response => {
        const index = this.clients.findIndex(client => client._id === clientId);
        if (index !== -1) {
          this.clients[index] = response.data;
        }
      })
      .catch(error => {
        console.error('Failed to update client:', error);
        appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
      }).finally(() => (this.updating = false));
  }

  @action deleteClient(clientId: string) {
    this.updating = true;
    axios.delete(`${apiUrl}/clients/${clientId}`)
      .then(() => {
        this.clients = this.clients.filter(client => client._id !== clientId);
      })
      .catch(error => {
        console.error('Failed to delete client:', error);
        appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
      }).finally(() => (this.updating = false));
  }

  // Add other store methods here
}

const clientStore = new ClientStore();
export default clientStore;
