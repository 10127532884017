import { action, makeAutoObservable, observable } from "mobx";
import axios from "axios";
import { Supplier } from "../interfaces/supplier";

const apiUrl =  process.env.REACT_APP_API_URL;

class SupplierStore {
  @observable suppliers: Supplier[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchSuppliers() {
    try {
      const response = await axios.get(`${apiUrl}/suppliers`);
      this.suppliers = response.data;
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  }

  @action
  async addSupplier(supplier: Supplier) {
    try {
      await axios.post(`${apiUrl}/suppliers`, supplier);
      // Assuming the API returns the newly added supplier,
      // you can append it to the existing list of suppliers
      this.suppliers.push(supplier);
    } catch (error) {
      console.error("Error adding supplier:", error);
    }
  }

  @action
  async updateSupplier(id: string, updatedSupplier: Partial<Supplier>) {
    try {
      await axios.put(`${apiUrl}/suppliers/${id}`, updatedSupplier);
      const supplierIndex = this.suppliers.findIndex((supplier) => supplier._id === id);
      if (supplierIndex !== -1) {
        // Update the existing supplier with the updated values
        this.suppliers[supplierIndex] = { ...this.suppliers[supplierIndex], ...updatedSupplier };
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
    }
  }

  @action
  async deleteSupplier(id: string) {
    try {
      await axios.delete(`${apiUrl}/suppliers/${id}`);
      this.suppliers = this.suppliers.filter((supplier) => supplier._id !== id);
    } catch (error) {
      console.error("Error deleting supplier:", error);
    }
  }
}

const supplierStore = new SupplierStore();
export default supplierStore;
