import { observable, action, makeObservable } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class UploadStore {
  @observable isUploading: boolean = false;
  @observable uploadProgress: number = 0;
  @observable error: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  uploadFile = async (file: File, description: string) => {
    try {
      this.isUploading = true;
      this.uploadProgress = 0;
      this.error = null;

      // Create a FormData object and append the file and description
      const formData = new FormData();
      formData.append('file', file);
      formData.append('media-description', description);

      // Send the FormData object as the payload using Axios
      const response: AxiosResponse = await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: any) => {
          // Update the upload progress
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      });

      appStore.showToast(true, 'success', 'Uploaded')
      this.isUploading = false;
      return response.data;
    } catch (error: any) {
      this.error = error.message;
      this.isUploading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while uploading')
    }
  };
}

const uploadStore = new UploadStore();
export default uploadStore;
