import authStore from './AuthStore';
import appStore from './AppStore';
import clientStore from './ClientStore';
import sizeStore from './SizeStore';
import colorStore from './ColorStore';
import typeGarmentStore from './TypeGarmentStore';
import designStore from './DesignStore';
import uploadStore from './UploadStore';
import garmentStore from './GarmentStore';
import addressStore from './AddressStore';
import supplierStore from './SupplierStore';
import fundStore from './FundStore';
import userStore from './UserStore';
import clientOrderStore from './ClientOrderStore';
import bareGarmentStore from './BareGarmentStore';
import garmentOrderStore from './GarmentOrderStore';
import feedBackStore from './FeedBackStore';
import criteriaStore from './CriteriaStore';

const stores  = {
  authStore,
  appStore,
  clientStore,
  sizeStore,
  colorStore,
  typeGarmentStore,
  designStore,
  uploadStore,
  garmentStore,
  addressStore,
  supplierStore,
  fundStore,
  userStore,
  clientOrderStore,
  bareGarmentStore,
  garmentOrderStore,
  feedBackStore,
  criteriaStore
};

export default stores;