import { FC, MouseEventHandler } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideMenu: FC = () => {
  const location = useLocation();

  const handleLiClick: MouseEventHandler<HTMLElement> = (e) => {
    const link = e.currentTarget.querySelector("a");
    if (link) {
      link.click();
    }
  }

  return (
    <ul className="py-4">
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname.includes('/client') ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="user" />
        <Link to="/client">
          Clients
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/order' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="bag-shopping" />
        <Link to="/order">
          Client orders
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/feed-back' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="comment" />
        <Link to="/feed-back">
          Feedbacks
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/fund' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="business-time" />
        <Link to="/fund">
          Fund operation
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname.includes('/supplier') ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="truck-field" />
        <Link to="/supplier">
          Supplier
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/design' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="spray-can" />
        <Link to="/design">
          Design
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/color' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="palette" />
        <Link to="/color">
          Colors
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/size' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="arrow-up-wide-short" />
        <Link to="/size">
          Size
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/criteria' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="list" />
        <Link to="/criteria">
          Criteria
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/garment' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="icons" />
        <Link to="/garment">
          Garments
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/bare-garment' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="shirt" />
        <Link to="/bare-garment">
          Bare garments
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/type' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="file-lines" />
        <Link to="/type">
          Garment types
        </Link>
      </li>
      <li onClick={handleLiClick} className={`px-4 py-2 hover:bg-gray-300 cursor-pointer ${location.pathname === '/garment-order' ? 'bg-gray-500 text-white' : ''}`}>
        <FontAwesomeIcon className="mr-2" icon="folder-open" />
        <Link to="/garment-order">
          Garment orders
        </Link>
      </li>
    </ul>
  )
}

export default SideMenu;