import { inject, observer } from 'mobx-react';
import { FC } from 'react';

const Toast: FC<any> = inject('appStore')(observer(({ appStore }) => {
  const toastClasses = `fixed z-100 bottom-10 left-1/2 transform -translate-x-1/2 p-4 rounded-md ${
    appStore.toastType === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
  }`;

  return <div className={toastClasses}>{appStore.toastMessage}</div>;
}));

export default Toast;
