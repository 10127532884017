import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';
import appStore from './AppStore';
import { Criteria } from '../interfaces/criteria';

const apiUrl =  process.env.REACT_APP_API_URL;

export class CriteriaStore {
  criteriaList: Criteria[] = [];

  constructor() {
    makeObservable(this, {
      criteriaList: observable,
      fetchCriteriaList: action,
      addCriteria: action,
      updateCriteria: action,
      deleteCriteria: action,
    });
  }

  async fetchCriteriaList() {
    try {
      const response = await axios.get(`${apiUrl}/criteria`);
      this.criteriaList = response.data;
    } catch (error: any) {
      console.error('Error fetching criteria:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async addCriteria(newCriteria: Criteria) {
    try {
      const response = await axios.post(`${apiUrl}/criteria`, newCriteria);
      this.criteriaList.push(response.data);
    } catch (error: any) {
      console.error('Error adding criteria:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  }

  async updateCriteria(updatedCriteria: Criteria) {
    try {
      const response = await axios.put(`${apiUrl}/criteria/${updatedCriteria._id}`, updatedCriteria);
      const index = this.criteriaList.findIndex((criteria) => criteria._id === updatedCriteria._id);
      if (index !== -1) {
        this.criteriaList[index] = response.data;
      }
    } catch (error: any) {
      console.error('Error updating criteria:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating criteria')
    }
  }

  async deleteCriteria(criteriaId: string) {
    try {
      await axios.delete(`${apiUrl}/criteria/${criteriaId}`);
      this.criteriaList = this.criteriaList.filter((criteria) => criteria._id !== criteriaId);
    } catch (error: any) {
      console.error('Error deleting criteria:', error);
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  }
}

// Create an instance of the CriteriaStore
const criteriaStore = new CriteriaStore();

export default criteriaStore;
