import { makeAutoObservable, runInAction } from 'mobx';
import axios from 'axios';
import { Order } from '../interfaces/order';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class ClientOrderStore {
  orders: Order[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchOrders = async () => {
    try {
      const response = await axios.get(`${apiUrl}/orders`);
      runInAction(() => {
        this.orders = response.data;
      });
    } catch (error: any) {
      console.error('Failed to fetch orders:', error);
      appStore.showToast(true, 'error', error?.response?.data?.message || 'An error occurred while fetching')
    }
  };

  addOrder = async (newOrder: Order) => {
    try {
      await axios.post(`${apiUrl}/orders`, newOrder);
      this.fetchOrders();
    } catch (error: any) {
      console.error('Failed to add order:', error);
      appStore.showToast(true, 'error', error?.response?.data?.message || 'An error occurred while adding')
    }
  };

  updateOrder = async (id: string, updatedOrder: Order) => {
    try {
      await axios.put(`${apiUrl}/orders/${id}`, updatedOrder);
      this.fetchOrders();
    } catch (error: any) {
      console.error('Failed to update order:', error);
      appStore.showToast(true, 'error', error?.response?.data?.message || 'An error occurred while updating')
    }
  };

  deleteOrder = async (id: string) => {
    try {
      await axios.delete(`${apiUrl}/orders/${id}`);
      runInAction(() => {
        this.orders = this.orders.filter((order) => order._id !== id);
      });
    } catch (error: any) {
      console.error('Failed to delete order:', error);
      appStore.showToast(true, 'error', error?.response?.data?.message || 'An error occurred while deleting')
    }
  };
}

const clientOrderStore = new ClientOrderStore();
export default clientOrderStore;
