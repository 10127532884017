import { inject, observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../../assets/logo.png";
import SideMenu from './SideMenu';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Layout: FC<any> = inject('authStore', 'appStore')(observer(({ children, authStore, appStore }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logout = () => {
    authStore.logOut();
    navigate('/login');
  };

  const openProfile = () => {
    navigate('/profile');
  }

  const goToHome = () => {
    navigate('/');
  }

  return (
    <div className="flex h-screen">

      {/* Left Side Menu */}
      <div
        ref={menuRef}
        className={`${
          isMenuOpen ? 'block' : 'hidden'
        } sm:block bg-gray-200 w-3/4 sm:w-1/6 absolute sm:relative h-screen sm:h-auto z-10`}
      >
        <div className="h-10 flex flex-row-reverse">
          <FontAwesomeIcon icon="remove" onClick={toggleMenu} className='cursor-pointer mr-2 mt-2 fa-2x sm:hidden' />
        </div>
        <SideMenu />
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-1">
        {/* Top Bar */}
        <div className="bg-gray-800 text-white p-4 flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center cursor-pointer">
            <img src={logo} alt="Logo" className="w-8 h-8 mr-2 hidden sm:block" onClick={goToHome} />
            <span className="text-lg font-bold hidden sm:block" onClick={goToHome}>OnlyWay</span>
            <button
              className="text-gray-400 hover:text-white focus:text-white sm:hidden"
              onClick={toggleMenu}
              aria-label="Toggle Menu"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 12h18M3 6h18M3 18h18" />
              </svg>
            </button>
            <FontAwesomeIcon icon={faHome} onClick={goToHome} className='cursor-pointer h-6 w-6 sm:hidden ml-2' />
          </div>

          {/* User Information and Logout Icon */}
          <div className="flex items-center">
            <span className="mr-2 cursor-pointer" onClick={openProfile}>{authStore.userInfo.name}</span>
            <FontAwesomeIcon onClick={logout} title='Disconnect' icon="power-off" className='cursor-pointer h-6 w-6 text-gray-400' />
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 p-4">{children}</div>
      </div>
    </div>
  );
}));

export default Layout;
