import { observable, action, makeObservable } from 'mobx';
import { Design } from '../interfaces/design';
import axios from 'axios';
import appStore from './AppStore';

const apiUrl =  process.env.REACT_APP_API_URL;

class DesignStore {
  @observable designs: Design[] = [];
  @observable isLoading: boolean = false;
  @observable error: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchDesigns = async () => {
    try {
      this.isLoading = true;
      this.error = null;
      const response = await axios.get(`${apiUrl}/designs`);
      this.designs = response.data;
      this.isLoading = false;
    } catch (error: any) {
      this.error = error.message;
      this.isLoading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while fetching')
    }
  };

  @action
  addDesign = async (design: Design) => {
    try {
      this.isLoading = true;
      this.error = null;
      const response = await axios.post(`${apiUrl}/designs`, design);
      this.designs = [...this.designs, response.data];
      this.isLoading = false;
    } catch (error: any) {
      this.error = error.message;
      this.isLoading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while inserting')
    }
  };

  @action
  updateDesign = async (id: string, design: Design) => {
    try {
      this.isLoading = true;
      this.error = null;
      const response = await axios.put(`${apiUrl}/designs/${id}`, design);
      const index = this.designs.findIndex(design => design._id === id);
      if (index !== -1) {
        this.designs[index] = response.data;
      }
      this.isLoading = false;
    } catch (error: any) {
      this.error = error.message;
      this.isLoading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while updating')
    }
  };

  @action
  deleteDesign = async (id: string) => {
    try {
      this.isLoading = true;
      this.error = null;
      await axios.delete(`${apiUrl}/designs/${id}`);
      this.designs = this.designs.filter(design => design._id !== id);
      this.isLoading = false;
    } catch (error: any) {
      this.error = error.message;
      this.isLoading = false;
      appStore.showToast(true, 'error', error?.response?.data?.errors?.[0] || 'An error occurred while deleting')
    }
  };
}

const designStore = new DesignStore();
export default designStore;
